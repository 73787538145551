import React, { Fragment, useEffect, useState } from 'react'
import ReactPlayer from 'react-player/youtube'
import './English.css'
import {motion} from 'framer-motion'
import moment from 'moment'
import { FaWindows } from 'react-icons/fa'
import background from '../../Assets/orchardplayer.png'
import { findDOMNode } from 'react-dom'
import { toast } from 'react-toastify';

function OrchardEnglish() {
  const today = moment().format("YYYY-MM-DD");
  const [date, setDate] = useState(today);
  const [firstname, setFirstName] = useState("");
  const [lastname, setLastName] = useState("");
  const [forms, setForm] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [form_id, set_form_id] = useState("");
  const form_submit = async (e) =>{
    e.preventDefault();

    if(validateInputs() == true){
   
      try{
  
        const id = 10;
        const user_id = localStorage.getItem("user_id")
        const first_name = firstname;
        const last_name = lastname;
        console.log(first_name)
        const body ={id,user_id,date,first_name,last_name}
        const response = await fetch(
        
          `${localStorage.getItem("url")}/api/completeForm`,
          {
            method: "POST",
            headers: { "content-type": "application/json" },
            body: JSON.stringify(body),
          }
        );
        

        window.alert("Answers are correct, form submitted.")
        document.getElementById("orchard_form").reset();
        
      
        
    } catch (err) {
      console.log(err.message);
    }
    
  }
}
   
const validateInputs = ()  =>  {
  const error = [];
  const blankValues = [];

  const group_1 = (document.getElementsByName("19"));
  const group_2 = (document.getElementsByName("24"));
  const group_3 = (document.getElementsByName("25"));
 


if(firstname.length == 0 || lastname.length == 0){
      window.alert("You must enter Firstname and Lastname");
         
      
    }else{
      group_1.forEach((entry) => {
        console.log(entry)
          if(entry.attributes.value.value == "yes"){
            if(entry.checked == true){
            return true
          }else{
            blankValues.push(entry)
          }
        }else 
          if(entry.checked == true){
            console.log("hello")
            error.push(entry)
          }else{
            blankValues.push(entry)
          }
        
      })

      group_2.forEach((entry) => {
        console.log(entry)
          if(entry.attributes.value.value == "yes"){
            if(entry.checked == true){
            return true
          }else{
            blankValues.push(entry)
          }
        }else 
          if(entry.checked == true){
            console.log("hello")
            error.push(entry)
          }else{
            blankValues.push(entry)
          }
        
      })

      group_3.forEach((entry) => {
        console.log(entry)
          if(entry.attributes.value.value == "yes"){
            if(entry.checked == true){
            return true
          }else{
            blankValues.push(entry)
          }
        }else 
          if(entry.checked == true){
            console.log("hello")
            error.push(entry)
          }else{
            blankValues.push(entry)
          }
        
      })

      if(error.length >= 1){
        window.alert("One or more answers are incorrect")
        return false
      }else if(blankValues.length > 3){
        window.alert("You must answer the all questions")
        return false
      }else{
     
        
     
        return true
     
         
        
      }
    }

    
   
}

const getForms = async (id) => {
var form_id = id;
var form_array_number = id - 1;
var jsonData2 = {};
try {
  const response2 = await fetch(
    `${localStorage.getItem("url")}/api/forms/${id}`
  );
  jsonData2 = await response2.json();
    console.log(jsonData2)
} catch (err) {
 
  console.log(err.message);
}

setForm(jsonData2);
set_form_id(id);





};

const getQuestions = async (id) =>{

var form_id = id;
var form_array_number = id - 10;
var jsonData2 = {};
try {
  const response2 = await fetch(
    `${localStorage.getItem("url")}/api/questions/${id}`
  );
  jsonData2 = await response2.json();
    console.log(jsonData2)
} catch (err) {
 
  console.log(err.message);
}

jsonData2.forEach((entry) => {
  if(!questions.includes(entry.question_id)){
    console.log(entry);
    questions.push(entry.question_id)
  }
  
})

};

useEffect(()  => {

getForms(10);
 getQuestions(10);

    }, []);


 
   
   

  return (
    <motion.div 
    initial={{opacity: 0, x: 50}} 
    animate={{opacity: 1, x: 0}} 
    exit={{opacity: 0, x: -50}}
    transition={{ ease: "easeInOut", duration: 0.5 }}
    >
      <div className="orchardeng_container">
        <div className="orchardeng_content">
          <div className="orchardeng_video">
              <h1>General Induction</h1>
              <div className="orchardvideo">
              <ReactPlayer 
                url='https://youtu.be/GDw8BcYrqj8?si=_sHeK93CJIfJ0hK_' 
                controls='true' 
                width="100%"
                height='100%'
                />
              </div>
              <h1>Orchard Induction</h1>
              <div className="orchardvideo">
              <ReactPlayer
                url='https://youtu.be/-M2WOGR70pA?si=Hiy9kl7tm1fypP6J' 
                controls='true' 
                width="100%"
                height='100%'
                />
              </div>
              <h1>Ladder Safety</h1>
              <div className="orchardvideo">
              <ReactPlayer
                url='https://youtu.be/u2e-GAKyYJY?si=XMxWdbcyxMeqwHHY' 
                controls='true' 
                width="100%"
                height='100%'
                />
              </div>
          </div>
          <div className="orchardeng_form">
            <h2>Orchard Form</h2>
            <form id='orchard_form'>
              <div className="formname_container">
                <div className="formname">

                  <input type="text" placeholder='Firstname' name="input" onChange={(e) => setFirstName(e.target.value)}></input>
                </div>
                <div className="formname">
                  <input type="text" placeholder='Lastname' name="input" onChange={(e) => setLastName(e.target.value)}></input>

                 
                </div>
              
              </div>
              <table>
            {forms.map((r) => (
                <div className="formquestion_container">
            <tr key={r.id}>
              <td><label>{r.question_text}</label>
              <div className="formquestion" id={r.question_id}>
                <div className='form_option'>
       
                  
                  {
                    r.AllTheAnswers.split( ':' ).map( ( item ) => <> 
                    <input
                  
                      name={r.question_id}
                      id={r.question_id}
                    
                      type="radio"  
                      value={item}  

                  
                    />
                    <label for={r.question_id}>{ item }</label>  <br /> </>)
                     }
                    </div>
              </div>
                  </td>
                </tr>
                </div>

              

              ))} 
            </table>
            </form>
            <button className="orchard_submit" onClick={form_submit}>Submit</button>
          </div>
        </div>
      </div>
    </motion.div>

  )
}

export default OrchardEnglish
