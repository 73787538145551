import React, { Fragment, useState, Component } from "react";
//Copyright © 2011-2015, Paul Vorbach.
//Copyright © 2009, Jeff Mott.
import md5 from "md5";
import sha1 from "sha1";
import Errorpage from "../Errorpage";
const Register = () => {
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onSubmitForm = async (e) => {
    e.preventDefault();

    try {
      const hash_password = md5(sha1(password));
      const body = { first_name, last_name, email, hash_password };
      const response = await fetch(
        `${localStorage.getItem("url")}/api/register`,
        {
          method: "POST",
          headers: { "content-type": "application/json" },
          body: JSON.stringify(body),
        }
      );

      window.location = "./login";
    } catch (err) {
      console.log(err.message);
    }
  };
  const check_admin = () => {
    if (localStorage.getItem("admin") == "1") {
      return true;
    } else {
      return false;
    }
  };

if(check_admin()){
  return (
    <Fragment>
      <form id="register" onSubmit={onSubmitForm} methgo="post">
        <div id="form-body">
          <h3>Register</h3>
          <div className="form-group">
            <label>First name</label>
            <input
              onChange={(e) => setFirstName(e.target.value)}
              type="text"
              className="form-control"
              placeholder="First name"
            />
          </div>
          <div className="form-group">
            <label>Last name</label>
            <input
              onChange={(e) => setLastName(e.target.value)}
              type="text"
              className="form-control"
              placeholder="Last name"
            />
          </div>
          <div className="form-group">
            <label>Email</label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="form-control"
              placeholder="Enter email"
            />
          </div>
          <div className="form-group">
            <label>Password</label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="form-control"
              placeholder="Enter password"
            />
          </div>
          <button
            type="submit"
            className="mt-5 btn btn-dark btn-lg btn-block"
            id="login-button"
          >
            Register
          </button>
          {/* <a type="" href="/login" className="btn btn-dark btn-lg btn-block">
            login
          </a> */}
          {/* <p className="forgot-password text-right">
            Already registered <a href="#">log in?</a>
          </p>{" "} */}
        </div>
      </form>
    </Fragment>
  );
}else{
  <Errorpage></Errorpage>
}
 
};
export default Register;
