import React from 'react'
import './Language.css'
import {Routes, Route, useNavigate, } from 'react-router-dom';
import {motion} from 'framer-motion'

function OrchardLanguage() {
  const navigate = useNavigate();

  const navigateOrchardEnglish = () => {
    navigate('/orchard-english');
  };
  return (
    <motion.div 
    initial={{opacity: 0, x: 50}} 
    animate={{opacity: 1, x: 0}} 
    exit={{opacity: 0, x: -50}}
    transition={{ ease: "easeInOut", duration: 0.5 }}
    >
      <div className="orchard_language-container">
        <div className="orchard_language-content">
          <div className="orchard_language-buttons">
            <h2>Orchard Language</h2>
          </div>
          <div className="orchard_language-buttons">
            <button className="flag english" onClick={navigateOrchardEnglish}></button>
            <button className="flag pijiin"></button>
            <button className="flag samoan"></button>
            <button className="flag thai"></button>
            <button className="flag tongan"></button>
          </div>
        </div>
    </div>
    </motion.div>
  )
}

export default OrchardLanguage