import React, { Fragment, useEffect, useState } from 'react';
import './DatabaseV2.css'
import Dashboard from './Dashboard';
import moment from 'moment';
import {motion} from 'framer-motion'

function DatabaseV2() {

  const [staff, setStaff] = useState([])
  const [questions, setQuestios] = useState([])



  const getStaffInducted = async () =>{
     
    var jsonData2 = {};
      try {
        const response2 = await fetch(
          `${localStorage.getItem("url")}/api/CompletedForms`
        );
        jsonData2 = await response2.json();
        
      } catch (err) {
       
        console.log(err.message);
      }
  
      setStaff(jsonData2);

     
  
    
  };

  const getQuestions = async (id) => {
    console.log(id)
   
    var jsonData2 = {};
    try {
      const response2 = await fetch(
        `${localStorage.getItem("url")}/api/GetQuestions/${id}`
      );
      jsonData2 = await response2.json();
    
    } catch (err) {
     
      console.log(err.message);
    }

    setQuestios(jsonData2);
  }

  const filter = async () =>{
    
    // Declare variables
    var ddl = document.getElementById("field");
    var selectedValue = ddl.options[ddl.selectedIndex].value;
    var input = document.getElementById('search');
    var filter = input.value.toUpperCase();

       if (selectedValue == "name")
      {
        for (var i = 0; i < staff.length; i++) {
      
          var a = staff[i].firstname + " " + staff[i].lastname;
        
        
          var tr = document.getElementById(staff[i].completed_form_id)
          
          if (a.toUpperCase().indexOf(filter) > -1) {
         
    
     
            tr.style.display = "";
          } else {
          
            tr.style.display = "none";
            console.log(tr)
            
          }
        }
      }else if (selectedValue == "Form")
      { for (var i = 0; i < staff.length; i++) {
      
        var a = staff[i].name;
      
        var tr = document.getElementById(staff[i].completed_form_id)
        if (a.toUpperCase().indexOf(filter) > -1) {
       
         
          
          tr.style.display = "";
        } else {
          console.log(staff[i].completed_form_id)

          tr.style.display = "none";
          
        }
      }
      }else if (selectedValue == "Date")
      { for (var i = 0; i < staff.length; i++) {
      
        var a = moment(staff[i].date).format("DD-MM-YYYY");
      
        var tr = document.getElementById(staff[i].completed_form_id)
        if (a.toUpperCase().indexOf(filter) > -1) {
       
         
          
          tr.style.display = "";
        } else {
          console.log(staff[i].completed_form_id)

          tr.style.display = "none";
          
        }
      }
      }

    
  
}


  // const getStaff = async () =>{
     
  //   var jsonData2 = {};
  //     try {
  //       const response2 = await fetch(
  //         `${localStorage.getItem("url")}/api/staff`
  //       );
  //       jsonData2 = await response2.json();
        
  //     } catch (err) {
       
  //       console.log(err.message);
  //     }
  
  //     setStaff(jsonData2);

     
  
    
  // };

  useEffect(()  => {

    // getStaff()

      getStaffInducted()
     
         }, []);

  return (
    <motion.div 
    initial={{opacity: 0, x: 50}} 
    animate={{opacity: 1, x: 0}} 
    exit={{opacity: 0, x: -50}}
    transition={{ ease: "easeInOut", duration: 0.5 }}
    >
    <div className="database_container">
      <div className="database_content">
        <h1>Induction Database</h1>
        
        <label for="field">Search by:</label>
    
<select name="field" id="field">
  <option value="name">Name</option>
  <option value="Form">Form name</option>
  <option value="Date">Date</option>
</select>
<div className="database_search">
<input placeholder='search' id="search" onKeyUp={filter}></input>
</div>
      

      <form className="databasetable" id="all_input" >
        <div className="database_table">
          <table className="styled-table">
            <tr>
              <th>Name</th>
              <th>Induction</th>
              <th>Date</th>
            </tr>
            {staff.map((r) => (
            <tr  id={r.completed_form_id} key={r.completed_form_id}>
                <td><label id={r.first_name + " " + r.last_name}><a href={'#'+r.completed_form_id + r.firstname + r.lastname + moment(r.date).format("DD-MM-YYYY")} onClick={(e) => getQuestions(r.completed_form_id)}>{r.firstname} {r.lastname}</a> </label></td>
          
               <td><label id={r.name}>{r.name}</label></td>
               <td><label id={moment(r.date).format("DD-MM-YYYY")}>{moment(r.date).format("DD-MM-YYYY")}</label></td>
              
              
              
        
               
               <div className="database_overlay">
          <div value={r.completed_form_id} id={r.completed_form_id + r.firstname + r.lastname + moment(r.date).format("DD-MM-YYYY")}  class="overlay">
            <div class="popup">
              <h2>{r.firstname} {r.lastname}</h2>
              <a class="close" href="#">&times;</a>
            <div class="content">
              <table className="overlaytable">
                <tr>
                  <th>Induction</th>
                  <th>Question</th>
                  <th>Answers</th>
                  <th>Date</th>
                </tr>
             
             
                  {questions.map((q) => (

                    
                    
                       <tr>
                    <td>{q.name}</td>
                    <td>{q.question_text}</td>
                    <td>{q.answer_text}</td>
                    <td>{moment(q.date).format("DD-MM-YYYY")}</td>
                    </tr>
                  ))}
                 
              
           
              </table>
            </div>
            </div>
          </div>
        </div>
                
                </tr>
                
              ))} 
          </table>
        </div>
        </form>
      </div>
    </div>
    </motion.div>
  )
}

export default DatabaseV2