import React,{useEffect, useState }from 'react'
import {Routes, Route, useNavigate, } from 'react-router-dom';
import '../Induction/Induction.css';

function Induction() {
  const user_id = localStorage.getItem("user_id")
  const [forms, setForm] = useState([]);

    const navigate = useNavigate();

    const navigateDashboard = () => {
      navigate('/dashboard');
    };

    const navigateOrchard = () => {
      navigate('/induction-orchard');
    };
  
    const navigateFields = () => {
      navigate('');
    };

    const navigatePackhouse = () => {
      navigate('/induction-packhouse');
    };

    const navigateFruitshop = () => {
      navigate('');
    };

    const getCompletedForms = async () => {
      

      var jsonData2 = {};
      try {
        const response2 = await fetch(
          `${localStorage.getItem("url")}/api/UsersCompletedForms/${user_id}`
        );
        jsonData2 = await response2.json();
          console.log(jsonData2)
      } catch (err) {
       
        console.log(err.message);
      }
  
      setForm(jsonData2);
    
 
      CheckCompletedForms(jsonData2);
      
      
  
    };
    const CheckCompletedForms = async (forms) => {

    
      forms.forEach((form) => {
        const completed_form = document.getElementById(form.form_id)
        console.log(completed_form)
        console.log(form)
        if(form.form_id == completed_form.id){
          completed_form.classList.add("green");
        }else{
          return
        }

      })
    }

    
    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

  useEffect(()  =>  {

    getCompletedForms(user_id);

    
        }, []);


  return (
    <div className="induction_container">
        <div className="header">
          <h1>INDUCTION</h1>
        </div>
        <div className="quicklinks">
            <button id="4" className="induction_orchard"  onClick={navigateOrchard}>
                <span  className="button_text">Orchard</span>
            </button>
            <button id="9" className="induction_fields"   onClick={navigateFields}>
                <span  className="button_text">Fields</span>
            </button>
            <button id="8" className="induction_packhouse"   onClick={navigatePackhouse}>
                <span  className="button_text">Packhouse</span>
            </button>
            <button id="5"  className="induction_fruitshop"   onClick={navigateFruitshop}>
                <span className="button_text">Fruit Shop</span>
            </button>
        </div>
        <div className="back">
          <button className="induction_back" onClick={navigateDashboard}>
            <span className="button_text">back</span>
          </button>
        </div>
    </div>
  )
}

export default Induction