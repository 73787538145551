import logo from './logo.svg';
import React, { Fragment } from "react";
import './App.css';
import Dashboard from './Pages/Dashboard';
import Induction from './Pages/Induction/Induction';
import Orchard from './Pages/Induction/Orchard';
import Errorpage from './Pages/Errorpage';
import Navbar from './Pages/Navbar'
import Database from './Pages/DatabaseV2.js'
import Login_Navbar from './Pages/login/Login_Navbar'
import OrchardLanguage from './Pages/Induction/OrchardLanguage'
import OrchardEnglish from './Pages/Induction/OrchardEnglish'
import { BrowserRouter as Router, Routes, Switch, Route, useLocation } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';
import AnimatedRoutes from './Pages/AnimatedRoutes.js'

import Login from "./Pages/login/login";
import Register from "./Pages/login/register";
function App() {

  if (process.env.NODE_ENV === "production") {
    localStorage.setItem(
      "url",
      "https://bayleyproduce.herokuapp.com"
     
    );
  } else {
    localStorage.setItem("url", "http://localhost:3000");
  }

  if (localStorage.getItem("authenticated") === "true") {
  return (
    
    <Fragment>
    <Router basename="/">
      <Navbar />
      <AnimatedRoutes />
    </Router>

    </Fragment>
    
    
  );
} else {
  return (
    <Fragment>
      <Router>
        <Routes>       
          <Route path="/" element={<Login />} />
      </Routes>
      </Router>
    </Fragment>
  );
}
}

export default App;
