import React, { useState, useEffect, Fragment } from "react";
import md5 from "md5";
import sha1 from "sha1";
import './login.css';
import logo from '../../Assets/logo.png';
import {motion} from 'framer-motion'

const Login = () => {
  const url = localStorage.getItem("url");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [re, setRe] = useState("HI");
  const [auth, setAuth] = useState("False");

 





  const onSubmitForm = async (e) => {
    e.preventDefault();
    document.getElementById("loader-wrapper").classList.add("loader-wrapper")
    document.getElementById("loading").classList.add("loading")

    try {
      const hash_password = md5(sha1(password));
      const body = { email, hash_password };
      const response = await fetch(`${localStorage.getItem("url")}/api/login`, {
        method: "POST",
        headers : { 
          'Content-Type': 'application/json',
          'Accept': 'application/json'
         },
        body: JSON.stringify(body),
      });
      console.log(body);
      response.json().then((result) => {
        if (result == "failed") {
          localStorage.setItem("authenticated", "False");

          alert("Email or Password are incorrect");
          window.location = ".";

        } else {
          localStorage.setItem("authenticated", "true");
          localStorage.setItem("first_name", result.first_name);
          localStorage.setItem("admin", result.admin)
          localStorage.setItem("user_id", result.user_id)

          window.location = "/Dashboard";
          window.on("load",function(){
            (".loader-wrapper").fadeOut("slow");
       });
        }
      });
    } catch (err) {
      console.log(err.message);
    }
  };
  // const onSubmitForm = async () => {
  //   try {
  //     const response = await fetch("http://localhost:3000/login");
  //     const jsonData = await response.json();
  //     console.log(jsonData);
  //     setRe(jsonData);
  //   } catch (err) {
  //     console.log(err.message);
  //   }
  // };

  // useEffect(() => {
  //   onSubmitForm();
  // }, []);



  return (
    <Fragment>
    <motion.div 
    initial={{opacity: 1}} 
    animate={{opacity: 1}} 
    exit={{opacity: 0}}
    transition={{ ease: "easeInOut", duration: 0.5 }}
    >
      <form id="login" onSubmit={onSubmitForm} className="login-container">
        <div id="form-body" className="login-content">
          <img src={logo} />

          <div className="form-group">
            <label>Email:</label>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              className="form-control"
              placeholder="Enter email"
            />
          </div>

          <div className="form-group">
            <label>Password:</label>
            <input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              type="password"
              className="form-control"
              placeholder="Enter password"
            />
          </div>

          {/* <div className="form-group">
            <div className="custom-control custom-checkbox">
              <input
                type="checkbox"
                className="custom-control-input"
                id="customCheck1"
              />
              <label className="custom-control-label" htmlFor="customCheck1">
                Remember me
              </label>
            </div>
          </div> */}

<div id="loader-wrapper" class="">
            <span  id="loading" class=""></span>
          </div>

          <button
            type="submit"
            className="mt-5 btn btn-lg btn-block"
            id="login-button"
          >
            LOGIN
          </button>
          

          {/* <p className="forgot-password text-right">
            Forgot <a href="#">password?</a>
          </p> */}
        </div>
      </form>
      </motion.div>
    </Fragment>
  );
};
export default Login;
